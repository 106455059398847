import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import InformationSection from '../../components/productpages/body/information';
import SetupSection from '../../components/productpages/body/SetupSection';
import HeroProduct from '../../components/productpages/hero/Hero';
import Spacer from '../../components/spacer';
import InformFirstImage from '../../images/InformFirstImage.png';
import InformSecondImage from '../../images/InformSecondImage.png';

const InformPage = () => {

    const { t, i18n } = useTranslation();

    function setLanguage(bool) {
        console.log(bool)
        if (bool) {
            return i18n.changeLanguage('nl')
        }
        return i18n.changeLanguage('en')

    }
    return (
        <div>
            <Navbar whiteBg={true} switchFunction={(bool) => { setLanguage(bool) }} />
            <HeroProduct subtext={t('settings.subtext')} heading={t('settings.heading')} />
            <Spacer />

            <InformationSection
                heading={t('settings.infoHeading')}
                firstTitle={t('settings.firstTitle')}
                firstSubtitle={t('settings.firstSubtitle')}
                firstParagraph={t('settings.firstParagraph')}
                image={InformFirstImage}
                secondTitle={t('settings.secondTitle')}
                secondSubtitle={t('settings.secondSubtitle')}
                secondParagraph={t('settings.secondParagraph')}
                secondImage={InformSecondImage}
            />
            <Spacer />
            <SetupSection />
            <Spacer />
            <Footer />
        </div>
    )
}

export default InformPage